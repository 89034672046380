import React from "react";
import {Box, Chip, Dialog, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {themeColors} from "../utils/MUIStylePresets";

export default function ImageDialog({handleClose, open, img}) {

    const handleCloseAssist = () => {
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleCloseAssist} maxWidth={"md"}>
            <Box my={2} mx={5} mb={7}>
                <Stack direction="row-reverse" mt={2}>
                    <Chip
                        variant="outlined"
                        icon={<CloseIcon/>}
                        label={
                            <Typography
                                variant={"smallbook"}
                                sx={{
                                    color: themeColors("text"),
                                    textTransform: "uppercase",
                                }}
                                onClick={handleCloseAssist}
                            >
                                Fermer
                            </Typography>
                        }
                    />
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justify-content={"center"}
                    mt={2}
                    border=".5px solid black"
                >
                    <img width={"100%"} src={img} alt={''}/>
                </Stack>
            </Box>
        </Dialog>
    );
}
