import {Box, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import MenuBar from "../MenuBar/MenuBar";
import build from "../assets/build.svg";
import {
    blackBoxSingleTitle,
    blackBoxTitle,
    themeColors,
} from "../utils/MUIStylePresets";
import {ServiceCard} from "../ServiceCard/ServiceCard";
import Footer from "../Footer/Footer";
import React from "react";
import {
    caseStudy,
    homeContent,
    subtiltle,
    title,
} from "../siteContent/siteContent";
import {CaseStudyCard} from "../CaseStudy/CaseStudyCard";
import ImageDialog from "../Dialogs/ImageDialog";
import arrow from "../assets/arrow.png";

export default function HomePage() {
    const [openImage, setOpenImage] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState();

    const mobile = useMediaQuery("(max-width:1280px)");
    const ultraMobile = useMediaQuery("(max-width:450px)");
    const giant = useMediaQuery("(min-width:1440px)");

    const ScrollToEl = (id) => {
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
    };

    return (
        <Stack
            direction="row"
            justifyContent={"center"}
            alignItems={"center"}
            mx={"5%"}
            mt={"2%"}
        >
            <Box maxWidth={1550}>
                <MenuBar scrollToEl={ScrollToEl}/>
                <Grid
                    container
                    direction={mobile ? "column" : "row"}
                    justifyContent="flex-start"
                    alignItems={
                        ultraMobile ? "flex-end" : mobile ? "center" : "flex-start"
                    }
                    spacing="34px"
                    mt={ultraMobile ? 0 : 4}
                    ml={ultraMobile ? -7 : mobile ? -5.5 : -6.5}
                >
                    <Grid item xs={mobile ? 12 : 6}>
                        <Stack spacing={ultraMobile ? 1 : 5}>
                            <Typography
                                sx={blackBoxTitle}
                                color="primary"
                                variant={"extrabold"}
                            >
                                {title}
                            </Typography>
                            <Grid
                                container
                                direction={"row"}
                                justify-content={"flex-start"}
                                alignItems={"flex-start"}
                                spacing={ultraMobile ? 3 : 2}
                            >
                                <Grid item xs={ultraMobile ? 0.5 : mobile ? 0.5 : 0.65}>
                                    <Box
                                        mt={
                                            ultraMobile ? 0.2 : mobile ? -0.12 : giant ? -0.2 : -0.8
                                        }
                                    >
                                        <img
                                            src={arrow}
                                            width={ultraMobile ? 12 : mobile ? 16 : 25}
                                            alt="arrow"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={10.5}>
                                    <Typography
                                        variant={"book"}
                                        sx={{
                                            color: themeColors("text"),
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {subtiltle}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 6}>
                        <Box display={"flex"} justifyContent={"flex-end"} mt={-1}>
                            <img src={build} width={mobile ? 300 : 400} alt="blog logo"/>
                        </Box>
                    </Grid>
                </Grid>
                {homeContent.map((e) => (
                    <Box mt={10} id={e.title} ml={ultraMobile ? -1.5 : 0}>
                        <Typography
                            sx={blackBoxSingleTitle}
                            color="primary"
                            variant={"extrabold"}
                        >
                            {e.title}
                        </Typography>
                        <Grid
                            container
                            direction={mobile ? "column" : "row"}
                            justifyContent="space-between"
                            alignItems="flex-start"
                            rowSpacing={6}
                            columnSpacing={5}
                            mt={ultraMobile ? -1 : 5}
                        >
                            {e.content.map(c => (
                                <ServiceCard props={c}/>
                            ))}
                        </Grid>
                    </Box>
                ))}
                <Box mt={10} id={"Projects"}>
                    <Typography
                        sx={blackBoxSingleTitle}
                        color="primary"
                        variant={"extrabold"}
                    >
                        Étude de cas
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        rowSpacing={5}
                        columnSpacing={5}
                        mt={ultraMobile ? 1 : 5}
                        pl={4}
                    >
                        {caseStudy.map((s) => (
                            <CaseStudyCard
                                props={{
                                    setOpenImage: setOpenImage,
                                    setCurrentImage: setCurrentImage,
                                    ...s,
                                }}
                            />
                        ))}
                    </Grid>
                </Box>
                <Box mt={15}>
                    <Footer/>
                </Box>
                <ImageDialog
                    open={openImage}
                    handleClose={() => setOpenImage(false)}
                    img={currentImage}
                />
            </Box>
        </Stack>
    );
}
