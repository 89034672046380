import {Avatar, Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {
    TeamAvatar, smallTeamAvatar, themeColors,
} from "../utils/MUIStylePresets";
import React from "react";
import arrow from "../assets/arrow.png";

export const ServiceCard = ({props}) => {

    const {img, height, alt, title, text, avatar} = props;
    const mobile = useMediaQuery("(max-width:700px)");
    const ultraMobile = useMediaQuery("(max-width:450px)");
    const centerImage = useMediaQuery("(max-width:1280px)");
    const giant = useMediaQuery("(min-width:1440px)");

    return (<Grid item xs={mobile ? 12 : 5.5}>
        <Grid
            container
            direction={mobile ? "column" : "row"}
            justify-content={"space-between"}
            spacing={2}
        >
            <Grid item xs={7}>
                <Grid
                    container
                    direction={"row"}
                    justify-content={"flex-start"}
                    alignItems={"flex-start"}
                    spacing={ultraMobile ? 4 : 2}
                >
                    <Grid item xs={ultraMobile ? 0.2 : mobile ? 0.5 : 1.25}>
                        <Box
                            mt={ultraMobile ? 0 : centerImage ? -0.12 : giant ? -0.5 : -1}
                            ml={ultraMobile ? 1 : 0}
                        >
                            <img
                                src={arrow}
                                width={ultraMobile ? 12 : centerImage ? 16 : 25}
                                alt="arrow"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography
                            mt={6}
                            variant={"bookbold"}
                            sx={{color: themeColors("text"), textTransform: "uppercase"}}
                            noWrap
                        >
                            {title}
                        </Typography>
                        <Box mt={2}>
                            <Typography
                                variant={"book"}
                                sx={{
                                    color: themeColors("text"), textTransform: "uppercase",
                                }}
                                minWidth={200}
                            >
                                {text}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Box
                    display={"flex"}
                    justifyContent={centerImage ? "center" : "flex-end"}
                    mt={ultraMobile ? 1 : 0}
                >
                    {avatar ? (<Avatar
                        sx={ultraMobile ? smallTeamAvatar : TeamAvatar}
                        src={img}
                        alt={alt}
                    />) : (<img
                        src={img}
                        height={ultraMobile ? height * 0.8 : height}
                        alt={alt}
                    />)}
                </Box>
            </Grid>
        </Grid>
    </Grid>);
};
