import "./App.css";
import { ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import { theme } from "./utils/MUIStylePresets";
import ReactGA from "react-ga4";
import Hotjar from "@hotjar/browser";

ReactGA.initialize("G-R5EVMR3P9N");

const siteId = 3745209;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
