import {Box, Stack, Typography} from "@mui/material";
import {primary, themeColors} from "../utils/MUIStylePresets";

export default function Footer() {
    return (
        <Box mb={10} ml={4}>
            <Stack>
                <Typography
                    variant={"smallbook"}
                    sx={{color: themeColors("text"), textTransform: "uppercase"}}
                >
                    © {new Date().getFullYear()} NextUp Solutions Sàrl
                </Typography>
                <Typography
                    variant={"smallbook"}
                    mt={0.5}
                    sx={{color: primary, textTransform: "uppercase"}}
                >
                    Chemin de Budron H 11
                </Typography>
                <Typography
                    variant={"smallbook"}
                    sx={{color: primary, textTransform: "uppercase"}}
                >
                    1052 Le Mont-sur-Lausanne
                </Typography>
            </Stack>
        </Box>
    );
}
