import { createTheme } from "@mui/material";

export const primary = "#AACD41";
export const secondary = "#6441cd";
export const text = "#000000";

/*
book: 300
regular: 400
bold: 700
extrabold: 800
ratio extrabold/book : -0.78 rem
 */

const baseBoldSize = 2.8;
const bookRatio = 0.524;
const medRatio = 0.78;
const smallRatio = 0.87;
const bigBookRatio = 2.15;

export const theme = createTheme({
  typography: {
    extrabold: {
      fontFamily: "brother-1816, sans-serif",
      fontSize: `${Number(baseBoldSize * medRatio).toFixed(2)}rem`,
      "@media (min-width:1440px)": {
        fontSize: `${baseBoldSize}rem`, // Adjust font size for screens with a width of 600px or more
      },
      "@media (max-width:450px)": {
        fontSize: `${Number(baseBoldSize * bookRatio).toFixed(2)}rem`, // Adjust font size for screens with a width of 600px or more
      },
      letterSpacing: "-0.017em", // in thousands of em in indesign
      lineHeight: 0.99, // *fontsize
      fontWeight: 800, // Customize font weight
    },
    book: {
      fontFamily: "brother-1816, sans-serif",
      fontSize: `${Number(baseBoldSize * bookRatio * medRatio).toFixed(2)}rem`,
      "@media (min-width:1440px)": {
        fontSize: `${Number(baseBoldSize * bookRatio).toFixed(2)}rem`, // Adjust font size for screens with a width of 600px or more
      },
      "@media (max-width:450px)": {
        fontSize: `${Number(
          baseBoldSize * bookRatio * medRatio * medRatio,
        ).toFixed(2)}rem`, // Adjust font size for screens with a width of 600px or more
      },
      letterSpacing: "-0.017em",
      lineHeight: 1.259,
      fontWeight: 300,
    },
    smallbook: {
      fontFamily: "brother-1816, sans-serif",
      fontSize: `${Number(
        baseBoldSize * bookRatio * medRatio * smallRatio,
      ).toFixed(2)}rem`,
      "@media (min-width:1440px)": {
        fontSize: `${Number(baseBoldSize * bookRatio * smallRatio).toFixed(
          2,
        )}rem`, // Adjust font size for screens with a width of 600px or more
      },
      "@media (max-width:450px)": {
        fontSize: `${Number(
          baseBoldSize * bookRatio * medRatio * smallRatio * smallRatio,
        ).toFixed(2)}rem`, // Adjust font size for screens with a width of 600px or more
      },
      letterSpacing: "-0.017em",
      lineHeight: 1.259,
      fontWeight: 300,
    },
    bookbold: {
      fontFamily: "brother-1816, sans-serif",
      fontSize: `${Number(baseBoldSize * bookRatio * medRatio).toFixed(2)}rem`,
      "@media (min-width:1440px)": {
        fontSize: `${Number(baseBoldSize * bookRatio).toFixed(2)}rem`, // Adjust font size for screens with a width of 600px or more
      },
      "@media (max-width:450px)": {
        fontSize: `${Number(
          baseBoldSize * bookRatio * smallRatio * smallRatio,
        ).toFixed(2)}rem`, // Adjust font size for screens with a width of 600px or more
      },
      letterSpacing: "-0.017em", // in thousands of em in indesign
      lineHeight: 0.99, // *fontsize
      fontWeight: 700, // Customize font weight
    },
    smallbookbold: {
      fontFamily: "brother-1816, sans-serif",
      fontSize: `${Number(
        baseBoldSize * bookRatio * medRatio * smallRatio,
      ).toFixed(2)}rem`,
      "@media (min-width:1440px)": {
        fontSize: `${Number(baseBoldSize * bookRatio * smallRatio).toFixed(
          2,
        )}rem`, // Adjust font size for screens with a width of 600px or more
      },
      letterSpacing: "-0.017em", // in thousands of em in indesign
      lineHeight: 0.99, // *fontsize
      fontWeight: 700, // Customize font weight
    },
    bigbookbold: {
      fontFamily: "brother-1816, sans-serif",
      fontSize: `${Number(
        baseBoldSize * bookRatio * medRatio * bigBookRatio,
      ).toFixed(2)}rem`,
      "@media (min-width:1440px)": {
        fontSize: `${Number(baseBoldSize * bookRatio * bigBookRatio).toFixed(
          2,
        )}rem`, // Adjust font size for screens with a width of 600px or more
      },
      "@media (max-width:450px)": {
        fontSize: `${Number(
          baseBoldSize * bookRatio * bigBookRatio * smallRatio,
        ).toFixed(2)}rem`, // Adjust font size for screens with a width of 600px or more
      },
      letterSpacing: "-0.017em",
      lineHeight: 0.99,
      fontWeight: 700,
    },
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small screens (phones)
      sm: 600, // Small screens (tablets)
      md: 960, // Medium screens (small laptops)
      lg: 1280, // Large screens (desktops)
      xl: 1920, // Extra large screens (large desktops)
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    text: {
      main: text,
    },
  },
});


export const ButtonTextStyle = (color) => {
  return color
    ? { color: color, textTransform: "uppercase" }
    : { textTransform: "uppercase" };
};

export const blackBoxTitle = {
  textTransform: "uppercase",
  background: "black",
  py: 1,
  my: -1,
  px: 1,
  ml: 6,
};

export const blackBoxSingleTitle = {
  textTransform: "uppercase",
  background: "black",
  px: 1,
  ml: 4,
};

export const ButtonSecondaryHover = {
  backgroundColor: "none",
  "&:hover": { color: primary, backgroundColor: "transparent" },
  "&:active": {
    color: primary,
    backgroundColor: "transparent",
    disableRipple: true,
  },
};

export const themeColors = (name) => {
  // eslint-disable-next-line default-case
  switch (name) {
    case "primary":
      return primary;
    case "secondary":
      return secondary;
    case "text":
      return text;
  }
};

export const BorderedCard = {
  borderRadius: 0,
  border: "1.5px solid #AACD41",
  boxShadow: "none",
  ":hover": {
    boxShadow: 12,
  },
};

export const invisibleCard = {
  boxShadow: "none",
};


export const TeamAvatar = {
  width: 180,
  height: 180,
};

export const smallTeamAvatar = {
  width: 150,
  height: 150,
};

