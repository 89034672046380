import vlad from "../assets/vlad.jpeg";
import alex from "../assets/alex2.jpg";
import paco from "../assets/paco.jpeg";
import farid from "../assets/farid.png";
import employee from "../assets/employee.svg";
import dev from "../assets/programmer.svg";
import stars from "../assets/stars.svg";
import cloud from "../assets/product_iteration.svg";
import analysis from "../assets/analysis.svg";
import offer from "../assets/offer.svg";
import iot from "../assets/iot.svg";
import building from "../assets/building.svg";
import launching from "../assets/launching.svg";
import vplus from "../assets/vplus.png";
import vplus_tech from "../assets/vplus_tech.png";
import vplus_window from "../assets/vplus_window.png";
import node from "../assets/node.png";
import express from "../assets/express.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import mongo from "../assets/mongo.jpg";
import nginx from "../assets/nginx.png";
import infomaniak from "../assets/infomaniak.svg";
import pwa from "../assets/pwa.png";
import ga from "../assets/ga.png";
import fbase from "../assets/fbase.png";
import onyv_long from "../assets/onyv_long.png";
import mapbox from "../assets/mapbox.png";
import onyv_myactivities from "../assets/onyv_myactivities.png";
import onyv_chat from "../assets/onyv_chat.png";
import onyv_notif from "../assets/onyv_notif.png";

export const title = "Accélérez votre entreprise Grâce à la digitalisation";
export const subtiltle =
    "Nous implémentons " +
    "les dernières technologies pour vous propulser au prochain niveau";

export const homeContent = [
    {
        // Title is also the div id ! Also Changes onClick menu button prop
        title: "Services",
        content: [
            {
                title: "Innovation Digitale",
                alt: "Innovation digitale",
                img: stars,
                height: 170,
                text: "Nous vous aidons à définir quels sont les processus de votre activité qui peuvent être digitalisés en créant des optimisations disruptives",
            },
            {
                title: "Plateformes cloud",
                alt: "Plateformes cloud",
                img: cloud,
                height: 150,
                text: "Nous vous soutenons dans votre processus de rénovation et digitalisation par la création d’une plateforme cloud adaptée à vos besoins",
            },
            {
                title: "Internet of Things (IoT)",
                alt: "Internet of Things (IoT)",
                img: iot,
                height: 170,
                text: "Conception, développement et déploiement des solutions IoT sur mesure des capteurs à la gestion des flux de données",
            },
            {
                title: "Data Science",
                alt: "Intelligence Artificielle",
                img: dev,
                height: 150,
                text: "Libérerez le plein potentiel de vos données en adoptant les derniers outils d’Intelligence artificielle et de machine learning",
            },
        ],
    },

    {
        title: "Démarche",
        content: [
            {
                img: analysis,
                height: 150,
                alt: "project requirements",
                title: "Anaylse des besoins",
                text:
                    "Derrière tout projet, se cache une vision alors parlons de la vôtre !" +
                    " Discutons de vos besoins et identifions ensemble les axes d’amélioration ou de" +
                    "(ré)novation de vos processus et assets digitaux",
            },
            {
                img: offer,
                height: 170,
                alt: "app construction",
                title: "Proposition d'une solution",
                text:
                    "Sur la base des besoins identifiés, nous vous proposons" +
                    " une solution adaptée et chiffrée avec une roadmap contenant les différentes étapes de réalisation",
            },
            {
                img: building,
                height: 160,
                alt: "app construction",
                title: "Construction du projet",
                text:
                    "Une fois l’offre l’acceptée, nous commençons la phase de construction" +
                    "de votre solution en utilisant les meilleures pratiques de développement afin d’en assurer la maintenabilité et fiabilité",
            },
            {
                img: launching,
                height: 150,
                alt: "deployment",
                title: "Suivi du déploiement",
                text:
                    "C'est parti déployons votre projet ! Une fois que vous avez validé la version beta et que" +
                    "nous avons intégré votre feedback, nous nous occupons d’intégrer la solution dans votre workflow",
            },
        ],
    },
    {
        title: "Team",
        content: [
            {
                title: "Vladimir Pavlyukov",
                img: vlad,
                height: 150,
                text:
                    "Développeur web avec plus de sept ans d’expérience dans le domaine. Au cours de sa carrière," +
                    " il a travaillé sur une variété de plateformes et a affiné ses compétences en développement de systèmes" +
                    " front-end et back-end. Vladimir détient plusieurs certificats, spécialisés dans des sujets" +
                    " tels que le cloud computing",
                avatar: true,
                /*skills: [
                            {alt: 'cloud dev', src: codechip, label: 'Développement Cloud'},
                            {alt: 'data science', src: datachip, label: 'Data Science'},
                            {alt: 'System Architecture', src: notechip, label: 'System Architecture'},
                            // {alt: 'Fuelled by coffee', src: coffeechip, label: 'Fuelled by coffee'}
                        ],*/
            },
            {
                title: "Alexandre Tissot",
                img: alex,
                text:
                    "Ingénieur en microtechnologie et électronique, Alexandre a acquis une expérience précieuse " +
                    "dans les systèmes embarqués IoT grâce à divers projets de recherche. Il est également un " +
                    "développeur cloud full stack certifié et titulaire d’un Master en gestion de l’Innovation." +
                    " Depuis 2019, il réalise de différents projets de transition digitale, lui fournissant une" +
                    " perspective unique sur les technologies et leurs applications. ",
                avatar: true,

                /*skills: [
                            {alt: 'cloud dev', src: codechip, label: 'Développement Cloud'},
                            {alt: 'innovation', src: rocketchip, label: 'Innovation'},
                            {alt: 'IoT Design', src: cloudupchip, label: 'IoT Design'},
                            // {alt: 'Going for it', src: coffeechip, label: 'Also accepts tea'}
                        ]*/
            },
            {
                title: "Francisco Vaquera",
                img: paco,
                text:
                    "Entrepreneur chevronné, spécialisé dans les " +
                    "sociétés informatiques, sa longue expérience couvre de nombreux domaines de" +
                    " l'informatique et de la gestion d’entreprise. " +
                    "En tant qu’Advisor et fort de cette expertise, Francisco" +
                    " fournit des conseils stratégiques à NextUp pour le développement " +
                    "de ses activités. Combinant ses connaissances techniques, son savoir-faire commercial et la" +
                    " qualité de son réseau, il est devenu un acteur important de l’équipe et du succès de l'entreprise.",
                avatar: true,

                /*  skills: [
                              {alt: 'BizDev', src: uparrow, label: 'Développement Commercial'},
                              {alt: 'innovation', src: rocketchip, label: 'Innovation'},
                              {alt: 'System Architecture', src: notechip, label: 'System Architecture'},
                              // {alt: 'Going for it', src: coffeechip, label: 'Also accepts tea'}
                          ]*/
            },
            {
                title: "Farid Mammadaliyev",
                img: farid,
                text:"Titulaire d’un doctorat avec plus de quatre ans d'expérience dans l'analyse de données, Farid est spécialisé dans l'utilisation d'algorithmes de pointe en apprentissage automatique et en apprentissage profond pour analyser différentes variétés de données. " +
                    "En plus de son expérience professionnelle, Farid contribue régulièrement à la communauté" +
                    " de l'analyse de données en partageant ses solutions analytiques et ses insights à travers " +
                    "des articles sur Medium et des vidéos sur YouTube.",
                avatar: true,

                /*  skills: [
                              {alt: 'BizDev', src: uparrow, label: 'Développement Commercial'},
                              {alt: 'innovation', src: rocketchip, label: 'Innovation'},
                              {alt: 'System Architecture', src: notechip, label: 'System Architecture'},
                              // {alt: 'Going for it', src: coffeechip, label: 'Also accepts tea'}
                          ]*/
            },
            {
                title: "Pourquoi pas vous ?",
                img: employee,
                text: "Nous sommes toujours à la recherche de talents exceptionnels pour rejoindre notre équipe de développement. Si vous êtes passionné par la technologie, que vous aimez résoudre des problèmes complexes et que vous souhaitez travailler dans un environnement stimulant et collaboratif, N'hésitez pas à nous contacter !",
                avatar: false,
                height: 200,
            },
        ],
    },
];

export const caseStudy = [
    {
        title: "Volt+",
        descr: "Plateforme d’agrégation des données de consommation énergétique",
        height: 500,
        chal:
            "Volt+ est la rénovation complète de l’ancienne plateforme de gestion des appareils de la" +
            "société Voltcontrol SA. Il s’agissait de réaliser une plateforme utilisant une partie de" +
            "l’ancienne architecture qui permettait de communiquer avec les appareils tout en offrant" +
            "une interface moderne et une rapidité décoiffante.",
        mep1:
            "Il a été nécessaire de contacter dans un premier temps toutes les parties prenantes qui" +
            "avaient réalisé une partie de la plateforme existante, afin de faire le bilan de " +
            "l’existant",
        mep2:
            "et de définir les axes de travail. Une fois que nous avions établi les" +
            "différents systèmes en interaction, nous avons pu définir une nouvelle architecture de" +
            "microservices avec un plan de migration correspondant. Il a également été crucial de" +
            "s’entretenir avec les utilisateurs non-techniques, afin de connaître leurs besoins" +
            "spécifiques et de s’assurer que l’interface soit la plus intuitive possible.",
        res:
            "Un affichage des données 242% plus rapide, 32 nouvelles fonctionnalités et une réduction" +
            " de 62% des coûts d’hébergement !",
        tech: [
            {label: "Node", src: node, alt: "node", url: "https://nodejs.org/"},
            {
                label: "Express",
                src: express,
                alt: "express",
                url: "https://expressjs.com/",
            },
            {label: "React", src: react, alt: "react", url: "https://react.dev/"},
            {
                label: "Redux",
                src: redux,
                alt: "redux",
                url: "https://redux.js.org/",
            },
            {
                label: "MongoDB",
                src: mongo,
                alt: "mongo",
                url: "https://www.mongodb.com/",
            },
            {
                label: "Nginx",
                src: nginx,
                alt: "nginx",
                url: "https://www.nginx.com/",
            },
            {
                label: "Infomaniak",
                src: infomaniak,
                alt: "infomaniak",
                url: "https://www.infomaniak.com/en",
            },
        ],
        imgs: [
            {
                src: vplus,
                xs: 12,
                height: 200,
                caption: "After Rain (Jeshu John - designerspics.com)",
            },
            {
                src: vplus_tech,
                xs: 6,
                height: 200,
            },
            {
                src: vplus_window,
                xs: 6,
                height: 200,
            },
        ],
    },
    {
        title: "Onyv",
        descr: "Plateforme d’organisation d’activités et de rencontres",
        height: 500,
        chal:
            "Onyv répond au besoin d’une nouvelle plateforme qui permet d’organiser des activités et" +
            "faire de nouvelles rencontres. Une certaine quantité de plateformes existent déjà, mais" +
            "sont relativement limitées. Cela s’explique par la difficulté de trouver un modèle" +
            "d’affaires" +
            "viable qui permettrait d’investir plus de temps et de ressources dans un tel projet." +
            "Il est difficile également d’atteindre une ‘masse critique’ d’utilisateurs pour assurer" +
            "un contenu attrayant sans une interface moderne et ultra-intuitive.",
        mep1:
            " Il a fallu itérer plusieurs fois notre maquette pour s’assurer que notre prototype offre les" +
            "fonctionnalités les",
        mep2:
            " plus essentielles. Cela a impliqué encore une fois plusieurs" +
            "entretiens avec les organisateurs et utilisateurs afin de définir exactement leurs besoins et de" +
            "trouver la solution technologique optimale. Cette solution a impliqué des questionnements autant" +
            "au niveau de la portabilité multiplatfome qu’un serveur robuste, capable de traiter un" +
            "volume de requêtes élevé de façon presque instantanée.",
        res:
            "Une plateforme d’organisation d’activités épurée, rapide avec un chat instantanée, un" +
            "système de recherche d’événement par géolocalisation et une place en finale du concours" +
            " BCN Boost Challenge.",
        tech: [
            {
                label: "firebase",
                src: fbase,
                alt: "Firebase",
                url: "https://firebase.google.com/",
            },
            {label: "React", src: react, alt: "react", url: "https://react.dev/"},
            {
                label: "Redux",
                src: redux,
                alt: "redux",
                url: "https://redux.js.org/",
            },
            {
                label: "Mapbox",
                src: mapbox,
                alt: "mapbox",
                url: "'https://www.mapbox.com/",
            },
            {
                label: "Application Web Progressive",
                src: pwa,
                alt: "pwa",
                url: "https://en.wikipedia.org/wiki/Progressive_web_app",
            },
            {
                label: "Google Analytics",
                src: ga,
                alt: "ga",
                url: "https://marketingplatform.google.com/about/analytics/",
            },
        ],
        imgs: [
            {
                src: onyv_long,
                xs: 6,
                height: 200,
            },
            {
                src: onyv_chat,
                xs: 6,
                height: 200,
            },
            {
                src: onyv_myactivities,
                xs: 6,
                height: 200,
            },
            {
                src: onyv_notif,
                xs: 6,
                height: 200,
            },
        ],
    },
];
