import {Box, Card, CardActionArea, Stack, Typography,} from "@mui/material";
import {invisibleCard, themeColors,} from "../utils/MUIStylePresets";

export default function CustomChip({props}) {
    const {label, src, alt, url, height} = props;
    return (
        <Card sx={invisibleCard}>
            <CardActionArea onClick={() => window.open(url, "_blank")}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Box my={1} mx={2}>
                        <img src={src} alt={alt} height={height}/>
                    </Box>
                    <Typography
                        mr={2}
                        variant={"smallbook"}
                        sx={{color: themeColors("text"), textTransform: "uppercase"}}
                    >
                        {label}
                    </Typography>
                </Stack>
            </CardActionArea>
        </Card>
    );
}
