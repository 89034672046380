import {
    Box,
    Button,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import logo from "../assets/logo.png";
import slogan from "../assets/nxup_slogan.png";
import {
    ButtonSecondaryHover,
    ButtonTextStyle,
    primary,
    themeColors,
} from "../utils/MUIStylePresets";

export default function MenuBar({scrollToEl}) {
    const mobile = useMediaQuery("(max-width:1280px)");
    const ultraMobile = useMediaQuery("(max-width:500px)");
    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <img
                    src={logo}
                    height={ultraMobile ? "25px" : mobile ? "30px" : "50px"}
                    alt="NextUp Solutions Sàrl logo"
                />
                <Box>
                    <Box mt={1.75}>
                        <img
                            src={slogan}
                            height={ultraMobile ? "20px" : mobile ? "35px" : "55px"}
                            alt="NextUp Solutions Sàrl slogan"
                        />
                    </Box>
                </Box>
            </Stack>
            <Box ml={mobile ? 2 : 4}>
                <Divider sx={{backgroundColor: primary}}/>
            </Box>
            <Stack
                mt={1}
                direction="row"
                spacing={3}
                alignItems="center"
                justifyContent={ultraMobile ? "center" : "flex-end"}
            >
                <Button sx={ButtonSecondaryHover} onClick={() => scrollToEl("Team")}>
                    <Typography
                        variant={"book"}
                        sx={ButtonTextStyle(themeColors("text"))}
                    >
                        Team
                    </Typography>
                </Button>
                <Button
                    sx={ButtonSecondaryHover}
                    onClick={() => scrollToEl("Projects")}
                >
                    <Typography
                        variant={"book"}
                        sx={ButtonTextStyle(themeColors("text"))}
                    >
                        Projets
                    </Typography>
                </Button>
                {/*
                    <Button sx={ButtonSecondaryHover} onClick={()=>navigate('/blogs')}>
                        <Typography variant={'h6'} sx={ButtonTextStyle()} >Blog</Typography>
                    </Button>
                    */}
                <Button color={"secondary"} href={`mailto:hello@nxup.ch`}>
                    <Typography
                        variant={"book"}
                        sx={ButtonTextStyle(themeColors("secondary"))}
                    >
                        Contact
                    </Typography>
                </Button>
            </Stack>
        </>
    );
}
