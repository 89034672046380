import {
    Box,
    Card,
    CardActionArea,
    CardMedia,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {BorderedCard, themeColors} from "../utils/MUIStylePresets";
import React from "react";
import CustomChip from "../CustomChip/CustomChip";

export const CaseStudyCard = ({props}) => {
    const {
        setCurrentImage,
        setOpenImage,
        title,
        descr,
        imgs,
        chal,
        mep1,
        mep2,
        res,
        tech,
    } = props;
    const mobile = useMediaQuery("(max-width:1280px)");
    const ultraMobile = useMediaQuery("(max-width:600px)");

    const handleImageClick = (img) => {
        setCurrentImage(img);
        setOpenImage(true);
    };

    return (
        <Grid item xs={12}>
            <Typography
                mt={6}
                variant={"bigbookbold"}
                color="primary"
                sx={{textTransform: "uppercase"}}
                noWrap
            >
                {title}
            </Typography>
            <Grid
                container
                direction={mobile ? "column" : "row"}
                justify-content={"flex-start"}
                alignContent={"flex-start"}
                spacing={2}
            >
                <Grid item xs={6}>
                    <Box mt={2}>
                        <Typography
                            variant={"book"}
                            sx={{color: themeColors("text"), textTransform: "uppercase"}}
                            minWidth={200}
                        >
                            {descr}
                        </Typography>
                        <Grid
                            container
                            direction={ultraMobile ? "column" : "row"}
                            justify-content={"flex-start"}
                            alignContent={"flex-start"}
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    direction={"column"}
                                    justify-content={"flex-start"}
                                    alignContent={"flex-start"}
                                    spacing={1}
                                    mt={2}
                                >
                                    <Grid item xs={ultraMobile ? 12 : 6}>
                                        <Typography
                                            variant={"bookbold"}
                                            sx={{
                                                color: themeColors("text"),
                                                textTransform: "uppercase",
                                            }}
                                            mr={1}
                                            minWidth={200}
                                        >
                                            Challenge
                                        </Typography>
                                        <Typography
                                            variant={"book"}
                                            sx={{
                                                color: themeColors("text"),
                                                textTransform: "uppercase",
                                            }}
                                            minWidth={200}
                                        >
                                            {chal}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={ultraMobile ? 12 : 6}>
                                        {" "}
                                        <Typography
                                            variant={"bookbold"}
                                            sx={{
                                                color: themeColors("text"),
                                                textTransform: "uppercase",
                                            }}
                                            mr={1}
                                            minWidth={200}
                                        >
                                            Mise en Place
                                        </Typography>
                                        <Typography
                                            variant={"book"}
                                            sx={{
                                                color: themeColors("text"),
                                                textTransform: "uppercase",
                                            }}
                                            minWidth={200}
                                        >
                                            {mep1}
                                            {ultraMobile ? ` ${mep2}` : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    direction={"column"}
                                    justify-content={"flex-start"}
                                    alignContent={"flex-start"}
                                    spacing={1}
                                    mt={2}
                                >
                                    {!ultraMobile && (
                                        <Grid item xs={6}>
                                            <Typography
                                                variant={"book"}
                                                sx={{
                                                    color: themeColors("text"),
                                                    textTransform: "uppercase",
                                                }}
                                                minWidth={200}
                                            >
                                                {mep2}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={6}>
                                        <Typography
                                            variant={"bookbold"}
                                            sx={{
                                                color: themeColors("text"),
                                                textTransform: "uppercase",
                                            }}
                                            mr={1}
                                            minWidth={200}
                                        >
                                            Resultat
                                        </Typography>
                                        <Typography
                                            variant={"book"}
                                            sx={{
                                                color: themeColors("text"),
                                                textTransform: "uppercase",
                                            }}
                                            minWidth={200}
                                        >
                                            {res}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Grid
                        container
                        direction={"row"}
                        justify-content={"space-between"}
                        alignContent={"flex-start"}
                        spacing={2}
                        mt={1}
                    >
                        {imgs.map((i) => (
                            <Grid item xs={i.xs}>
                                <Card sx={BorderedCard}>
                                    <CardActionArea onClick={() => handleImageClick(i.src)}>
                                        <CardMedia sx={{height: i.height}} image={i.src}/>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={5}>
                            <Typography
                                color={"primary"}
                                variant={"smallbookbold"}
                                sx={{color: themeColors("text"), textTransform: "uppercase"}}
                            >
                                Technologies
                            </Typography>
                        </Box>
                        <Grid
                            container
                            direction={"row"}
                            justify-content={"flex-start"}
                            alignContent={"flex-start"}
                            spacing={1}
                            mt={2}
                        >
                            {tech.map((t) => (
                                <CustomChip props={{height: ultraMobile ? 15 : 20, ...t}}/>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
